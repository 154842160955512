import fetch from 'node-fetch';
import {
  handleJsonResponse,
  withDefaultFetchOptions,
} from 'reservation-frontend-commons';

/**
 * 店舗APIサービス
 */
export class ApiAgencyService {
  /**
   * AGS-API-100: (v2)地域情報リスト取得
   * @returns fetch Promise 地域情報一覧
   */
  async findAllRegions() {
    const apiUrl = '/api/v2/regions';
    const options = withDefaultFetchOptions({});
    const response = await fetch(apiUrl, options).then(handleJsonResponse);

    return response.regionList;
  }

  /**
   * AGS-API-116: 地域（支社）に属する都道府県情報一覧取得
   * @param {string} regionCode 地域（支社）コード
   * @returns fetch Promise 都道府県情報一覧
   */
  async findAreasByRegionCode(regionCode) {
    const apiUrl = '/api/v2/areas';
    const requestUrl = regionCode
      ? `${apiUrl}?regionCode=${regionCode}`
      : apiUrl;
    const options = withDefaultFetchOptions({});
    const response = await fetch(requestUrl, options).then(handleJsonResponse);

    return response.areaList;
  }

  /**
   * 前画面で選択したブランドと機種を選択可能な店舗が存在する地区（市区町村）一覧取得
   * @param {string} areaCode 地域コード
   * @param {string} brandCode ブランドコード
   * @param {string} modelCategoryCode 機種カテゴリコード
   * @returns fetch Promise 地区（市区町村）情報一覧
   */
  async findCityByAreaCode(areaCode, brandCode, modelCategoryCode) {
    const apiUrl = '/api/v1/cities';
    const requestUrl = `${apiUrl}/${areaCode}?brandCode=${brandCode}&modelCategoryCode=${modelCategoryCode}`;
    const options = withDefaultFetchOptions({});
    const response = await fetch(requestUrl, options).then(handleJsonResponse);

    return response.cityList;
  }

  /**
   * 前画面で選択したブランドと機種を選択可能かつ、指定した地区代表コードと拠点区分に属する店舗一覧取得
   * @param {string} cityDaihyoCode 地区代表コード
   * @param {string} brandCode ブランドコード
   * @param {string} modelCategoryCode 機種カテゴリコード
   * @returns fetch Promise 店舗情報一覧
   */
  async findShopByCityDaihyoCode(cityDaihyoCode, brandCode, modelCategoryCode) {
    const apiUrl = '/api/v1/shops';
    const requestUrl = `${apiUrl}/${cityDaihyoCode}?brandCode=${brandCode}&modelCategoryCode=${modelCategoryCode}`;
    const options = withDefaultFetchOptions({});
    const response = await fetch(requestUrl, options).then(handleJsonResponse);

    return response.reservableAgencyShopList;
  }
}
