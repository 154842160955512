import { $, addClass, on, removeClass } from 'dom7';
import autocomplete from 'autocompleter';
import { MAIL_DOMAIN_CANDIDATE } from '../utils/constants';

$.fn.on = on;
$.fn.addClass = addClass;
$.fn.removeClass = removeClass;

/**
 * メールドメインのautocomplete機能です
 * 指定したクラス名の全てのinputDOMにメールドメインのautocomplete機能を設定します。
 */
export const setMailAutocomplete = (targetClassName) => {
  // autocompleterはinput要素としてElementを使用する必要があるためdom7を使用しない。
  const elementArray = Array.from(
    document.getElementsByClassName(targetClassName)
  );
  elementArray.forEach((x) => setAutocomplete(x));
};

/**
 * 対象のインプットにautocompleteを設定します。
 * @param {Element} $inputElement autocompleteを設定するinput Element（Dom7やjQuery elementは不可）
 */
const setAutocomplete = (inputElement) => {
  // IME起動有無の判定フラグ。autocompleter内でイベントを取得できないため。
  let isComposing = false;

  // autocompleteの設定
  autocomplete({
    minLength: 1,
    input: inputElement,
    fetch: (text, update) => {
      let suggestions = [];
      if (text.indexOf('@') !== -1 && !isComposing) {
        const testBeforeDomain = text.split('@')[0];
        const candidate = MAIL_DOMAIN_CANDIDATE.map((x) => {
          return {
            label: `${testBeforeDomain}@${x}`,
            value: `${testBeforeDomain}@${x}`,
          };
        });
        suggestions = candidate.filter((n) => n.label.startsWith(text));
      }
      update(suggestions);
    },
    onSelect: (item) => $(inputElement).val(item.value),
    disableAutoSelect: true,
    preventSubmit: true,
  });

  // IME起動有無を判断するフラグの更新
  $(inputElement).on('keyup', (e) => {
    isComposing = e.isComposing;
  });
};
