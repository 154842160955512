import { $, append, addClass, removeClass, empty } from 'dom7';
import { MESSAGES, VALIDATION_MESSAGES } from './constants';
import { messageResolverProvider } from 'reservation-frontend-commons';

$.fn.append = append;
$.fn.addClass = addClass;
$.fn.removeClass = removeClass;
$.fn.empty = empty;

/**
 * messages.jsonからメッセージIDに紐づくメッセージを返す関数を返します。
 * @param messageId 取得したいメッセージのメッセージID
 */
export const getMessage = messageResolverProvider(MESSAGES);

/**
 * validationMessages.jsonからメッセージIDに紐づくメッセージを返す関数を返します。
 * @param messageId 取得したいメッセージのメッセージID
 * @param args プレースホルダーに格納する文字列（プレースホルダーの数だけ指定可能）
 * ```sample
 * getValidationMessage("ID0000", "置換文字列1", "置換文字列2", ...)
 * ```
 */
export const getValidationMessage =
  messageResolverProvider(VALIDATION_MESSAGES);

/**
 * validationMessages.jsonからメッセージIDに紐づくメッセージを取得してプレースホルダーを置換する関数を返します。
 * @param messageId 取得したいメッセージのメッセージID
 * ```sample
 * const getRequiredErrorMessage = getValidationMessageFunc("ID0000");
 *
 * getRequiredErrorMessage("置換文字列1", "置換文字列2", ...)
 * ```
 */
export const getValidationMessageFunc =
  (messageId) =>
  (...args) =>
    getValidationMessage(messageId, ...args);

/**
 * エラーメッセージDOMを削除し、エラー表示領域を非表示状態にします。
 */
export const clearErrorMessage = () => {
  const $errorBox = $('.js-error_box');
  const $errorList = $('.js-error_list');

  $errorBox.addClass('is-hide');
  $errorList.empty();
};

/**
 * エラーメッセージをDOMに追加し、エラー表示領域を表示状態にします。
 * @param {string} errorMessage
 */
export const setErrorMessage = (errorMessage) => {
  const $errorBox = $('.js-error_box');
  const $errorList = $('.js-error_list');

  $errorList.append(`<li class="errList__item">${errorMessage}</li>`);
  $errorBox.removeClass('is-hide');
};

/**
 * ボタン活性/非活性制御
 * @param {boolean} flag true:次画面遷移ボタンの活性 : false:次画面遷移ボタンの非活性
 */
export const activateNextButton = (flag) => {
  if (flag) {
    $('.to_next').removeClass('is-disabled').prop('disabled', false);
  } else {
    $('.to_next').addClass('is-disabled').prop('disabled', true);
  }
};
